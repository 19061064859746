
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    useMeta({
      title: "Privacy",
      description: `Il titolare del trattamento dei dati personali raccolti attraverso il sito
      www.lacasaincampagna.com è La casa in campagna Via dell'olio, 6 –
      CAMPOMARINO`,
    });
  },
});
