<template>
  <div class="container q-pb-xl">
    <div class="flex justify-center">
      <h4 v-if="$q.platform.is.mobile">Privacy Policy Modulo di Contatto</h4>
      <h3 v-else>Privacy Policy Modulo di Contatto</h3>
    </div>

    <p>
      In questa sezione si descrivono le modalità gestione dei dati personali
      che l’utente decide di fornire attraverso l’invio del modulo di contatto
      presente sul sito www.lacasaincampagna.com, sito di proprietà di La casa
      in campagna. Si tratta di informazioni rese agli utenti in relazione alle
      norme previste dal Regolamento UE 2016/679 ed in particolare degli artt.
      12-23 dello stesso Regolamento.
    </p>

    <h6>Titolare del trattamento</h6>
    <p>
      Il titolare del trattamento dei dati personali raccolti attraverso il sito
      www.lacasaincampagna.com è La casa in campagna Via dell'olio, 6 –
      CAMPOMARINO
    </p>

    <h6>Dati raccolti con il modulo di contatto</h6>
    <p>Compilando il modulo di contatto l’utente:</p>
    <ul>
      <li>comunica il proprio nome;</li>
      <li>comunica l’indirizzo email al quale vuole essere contattato;</li>
      <li>
        comunica numero di telefono al quale vuole essere contattato; scrive un
        messaggio da inviare a luciaingiuseppe@gmail.com
      </li>
    </ul>

    <h6>Finalità del trattamento</h6>
    <p>
      Il nome, l’indirizzo email e il numero di telefono indicato dall’utente al
      momento della compilazione del modulo di contatto e le informazioni
      inserite dall’utente nel corpo del messaggio sono utilizzate
      esclusivamente per rispondere alla richiesta dell’utente stesso.
    </p>

    <h6>Destinatari dei dati personali</h6>
    <p>
      I dati personali raccolti da La casa in campagna tramite
      www.lacasaincampagna.com potranno essere trattati da soggetti coinvolti
      nell’organizzazione del sito (come per esempio personale amministrativo,
      commerciale, marketing, legali, amministratori di sistema) o da personale
      esterno (come fornitori di servizi tecnici terzi, corrieri postali,
      hosting provider, società informatiche, agenzie di comunicazione) di volta
      in volta nominate, se necessario, Responsabili del trattamento a norma
      dell’art. 4 n.8) GDPR che definisce come “responsabile del trattamento” la
      persona fisica o giuridica, l’autorità pubblica, il servizio o altro
      organismo che tratta dati personali per conto del titolare del
      trattamento. In ogni caso ogni utente potrà sempre richiedere al Titolare
      del trattamento l’elenco aggiornato dei Responsabili del trattamento con
      le modalità di cui alla sezione relativa ai diritti dell’utente (ossia
      mediante invio di comunicazione mail a luciaingiuseppe@gmail.com ) Ad
      eccezione delle ipotesi appena indicate i dati personali dell’utente non
      vengono comunicati a terzi salvo:
    </p>

    <ul>
      <li>
        l’utente abbia rilasciato il proprio consenso espresso alla
        comunicazione;
      </li>
      <li>
        la comunicazione sia necessaria per fornire il servizio richiesto;
      </li>
      <li>sia richiesto dalla legge.</li>
    </ul>

    <h6>Periodo di conservazione</h6>
    <p>
      Le informazioni date dall’utente con la compilazione del modulo di
      contatto (nome, indirizzo email, numero di telefono,testo del messaggio)
      verranno trattati e conservati per il tempo strettamente necessario per la
      realizzazione della finalità per cui è stato raccolto, ossia per l’invio
      della risposta al quesito sottoposto.
    </p>

    <h6>Diritto di accesso</h6>
    <p>
      L’interessato ha il diritto di chiedere al Titolare del trattamento se sia
      in corso un trattamento di dati personali che lo riguardano e in caso
      affermativo di ottenere l’accesso agli stessi e le seguenti informazioni:
    </p>
    <ol>
      <li>Le finalità del trattamento;</li>
      <li>le categorie di dati in questione;</li>
      <li>
        i destinatari o le categorie di destinatari a cui i dati personali sono
        stati o saranno comunicati, in particolare se destinatari di paesi terzi
        o organizzazioni internazionali;
      </li>
      <li>
        quando possibile, il periodo di conservazione dei dati personali
        previsto oppure, se non è possibile, i criteri utilizzati per
        determinare tale periodo;
      </li>
      <li>
        l’esistenza del diritto dell’interessato di chiedere al titolare del
        trattamento la rettifica o la cancellazione dei dati personali o la
        limitazione del trattamento dei dati personali che lo riguardano o di
        opporsi al loro trattamento;
      </li>
      <li>il diritto di proporre reclamo a un’autorità di controllo;</li>
      <li>
        qualora i dati non siano raccolti presso l’interessato, tutte le
        informazioni disponibili sulla loro origine;
      </li>
      <li>
        l’esistenza di un processo decisionale automatizzato, compresa la
        profilazione di cui all’articolo 22, paragrafi 1 e 4, e, almeno in tali
        casi, informazioni significative sulla logica utilizzata, nonché
        l’importanza e le conseguenze previste di tale trattamento per
        l’interessato.
      </li>
    </ol>

    <p>
      Qualora i dati personali siano trasferiti a un paese terzo o a
      un’organizzazione internazionale, l’interessato ha il diritto di essere
      informato dell’esistenza di garanzie adeguate ai sensi dell’articolo 46
      relative al trasferimento.
    </p>

    <p>La casa in campagna specifica che:</p>
    <ol>
      <li>
        Non comunica i dati personali degli utenti a organizzazioni
        internazionali o a paesi terzi;
      </li>
      <li>
        L’interessato ha in ogni momento la possibilità di chiedere la rettifica
        o la cancellazione dei propri dati personali secondo le modalità
        indicate nella relativa sezione (ossia mediante invio di comunicazione
        mail a luciaingiuseppe@gmail.com).
      </li>
      <li>
        I dati personali non vengono raccolti con procedimenti completamente
        automatizzati, ma è sempre necessario il consenso dell’utente che
        inserisce i propri dati nei form predisposti sul sito.
      </li>
    </ol>

    <h6>Diritto alla cancellazione e diritto di rettifica</h6>
    <p>L’interessato ha il diritto di ottenere dal titolare del trattamento:</p>
    <ol>
      <li>la rettifica dei dati, qualora non siano corretti</li>
      <li>la cancellazione dei propri dati personali.</li>
    </ol>

    <p>
      Per l’esercizio di questo diritto è possibile inviare una richiesta
      scritta a luciaingiuseppe@gmail.com Il titolare del trattamento
      provvederà, senza ritardo, nel pieno rispetto dell’art. 17 del Regolamento
      europeo alla cancellazione richiesta.
    </p>

    <h6>Diritto di limitazione del trattamento</h6>
    <p>
      L’interessato ha il diritto di ottenere dal titolare del trattamento la
      limitazione del trattamento stesso nelle seguenti ipotesi:
    </p>
    <ul>
      <li>
        l’interessato contesta l’esattezza dei propri dati: per il tempo
        necessario al titolare del trattamento per verificarne l’esattezza;
      </li>
      <li>
        trattamento illecito: l’interessato si oppone alla cancellazione e
        chiede che ne venga limitato l’utilizzo;
      </li>
      <li>
        l’interessato si oppone al trattamento a norma dell’art. 21 paragrafo 1
        in attesa della verifica in merito all’eventuale prevalenza dei motivi
        legittimi del titolare del trattamento rispetto a quelli
        dell’interessato.
      </li>
    </ul>

    <p>
      La casa in campagna precisa che: Il trattamento dei dati personali avviene
      esclusivamente previo rilascio del consenso dell’utente, unicamente per le
      finalità esplicitate di volta in volta al momento della raccolta dei dati
      personali. Qualora l’interessato ritenesse di esercitare il diritto di
      limitazione del trattamento potrà inviare una richiesta scritta a
      luciaingiuseppe@gmail.com. Diritto di proporre reclamo L’interessato ha il
      diritto di proporre reclamo presso l’Autorità di controllo.
    </p>
    <q-btn color="primary" label="Indietro" :to="{ name: 'Contatti' }" />
  </div>
</template>

<script lang="ts">
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    useMeta({
      title: "Privacy",
      description: `Il titolare del trattamento dei dati personali raccolti attraverso il sito
      www.lacasaincampagna.com è La casa in campagna Via dell'olio, 6 –
      CAMPOMARINO`,
    });
  },
});
</script>
